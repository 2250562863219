import React from "react";
import MobileImg from "../images/mobile.png";

const MobileContainer = () => {
  return (
    <section className="bg-primary -mx-3 px-3 min-h-[900px] md:min-h-[500px]">
      <div className="max-w-6xl md:mx-auto p-2.5 pb-1 md:p-1 flex flex-col md:flex-row">
        <div className="w-full md:w-7/12">
          <div className="p-2.5 mt-5 mb-12 break-words leading-5 text-slate-700">
            <h2 className="mb-5 text-2xl font-bold text-black lg:text-4xl">
              A more complete up-front application leads to faster processing
              time
            </h2>
            <h4 className="font-bold">User-Driven Analytics</h4>
            <p className="mb-4">
              Matrix takes a data-driven approach to the experience. Our goal is
              to assist tenants through the simplest process possible.
            </p>
            <h4 className="font-bold">Automated Workflow Management</h4>
            <p>
              Matrix assembles application packages in an easy to read, easy to
              process format.
            </p>
          </div>
        </div>
        <div className="w-full md:w-5/12">
          <div className="mt-0 md:-mt-12 p-2.5 pb-0 md:p-0">
            <img src={MobileImg} alt="Mobile" className="w-auto mx-auto" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MobileContainer;
