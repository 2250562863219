import React from "react";

const LandingVideo = () => {
  return (
    <section className="flex flex-col max-w-6xl md:mx-auto p-2.5 pb-12 py-12 md:py-16 lg:py-24">
      <iframe
        src="https://assets.mymatrixrent.com/home_hero.mp4"
        className="w-full h-full aspect-video"
        title="youtube"
        allow="fullscreen"
        typeof="mp4"
      />
    </section>
  );
};

export default LandingVideo;
