import * as React from "react";
import { ROUTE_LINKS } from "../utils/routes";
import PageWrapper from "../components/PageWrapper";
import Landing from "../components/Landing";
import LandingVideo from "../components/LandingVideo";
import MobileContainer from "../components/MobileContainer";
import SEO from "../components/SEO";

const HomePage: React.FC = () => {
  return (
    <PageWrapper path={ROUTE_LINKS.index}>
      <SEO pageUrl={ROUTE_LINKS.index} />
      <main>
        <Landing />
        <LandingVideo />
        <MobileContainer />
      </main>
    </PageWrapper>
  );
};

export default HomePage;
