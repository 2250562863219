import React from "react";
import { Link } from "gatsby";
import HeroImage from "../images/amico.png";
import HomeImage1 from "../images/pana-1.png";
import HomeImage2 from "../images/amico-1.png";
import HomeImage3 from "../images/rafiki-1.png";
import ImageContainer from "./ImageContainer";
import { ROUTE_LINKS } from "../utils/routes";

const Landing = () => {
  return (
    <>
      <section className="flex flex-col max-w-6xl md:mx-auto p-2.5 ">
        <div className="flex flex-col items-center md:flex-row min-h-[500px] md:min-h-[400px] lg:min-h-[500px] space-y-4 md:space-y-0">
          <div className="w-full break-words md:w-5/12">
            <h2 className="font-bold text-semiLarge lg:text-large lg:leading-64 leading-48">
              <span className="heading">Matrix Rental</span>
              <br />
              <span>Solutions</span>
            </h2>
            <p className="mb-5 text-slate-700">
              The first universal rental application built to help both renters
              and landlords. To get started, select the option that applies to
              you below
            </p>
            <span className="text-slate-700 text-lg mb-3 flex">
              I am Looking to&nbsp;
              <Link
                to={ROUTE_LINKS.renterLanding}
                className="text-primary text-lg font-bold inline"
              >
                Rent
              </Link>
            </span>
            <span className="text-slate-700 text-lg flex">
              I am a Property&nbsp;
              <Link
                to={ROUTE_LINKS.managerLanding}
                className="text-primary text-lg font-bold inline"
              >
                Manager
              </Link>
            </span>
          </div>
          <div className="w-full md:w-7/12 md:ml-4">
            <img src={HeroImage} alt="Hero Image" title="Hero Image" />
          </div>
        </div>
        <div className="flex flex-col mt-24 md:flex-row">
          <ImageContainer
            image={HomeImage1}
            title="Better Experience"
            content="Matrix provides a fully digital experience that can be completed in
          minutes, from your mobile, tablet or desktop. Login, connect your
          accounts and you’re done."
          />
          <ImageContainer
            image={HomeImage2}
            title="Faster Turnaround"
            content="Once your application is completed, our technology analyzes your
          data and creates a decision ready application package."
          />
          <ImageContainer
            image={HomeImage3}
            title="One Application"
            content="You want your dream home sooner than later and if you don’t get it
            on the first shot, no sweat! Find a home in our network... at no
            additional cost."
          />
        </div>
      </section>
    </>
  );
};

export default React.memo(Landing);
