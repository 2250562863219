import React from "react";

interface ImageContainerProps {
  image?: any;
  title: string;
  content: string;
}
const ImageContainer: React.FC<ImageContainerProps> = ({
  image,
  title,
  content,
}) => {
  return (
    <div className="w-full md:w-1/3 p-2 flex-shrink ">
      <img
        src={image}
        alt={title}
        title={title}
        className="max-h-64 md:h-48 lg:h-64 object-cover min-h-[250px]"
      />
      <h2 className="mt-2 mb-1 font-bold">{title}</h2>
      <p className="text-slate-700">{content}</p>
    </div>
  );
};

export default ImageContainer;
